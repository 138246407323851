@use 'sass:color';
@use 'color-themes';
@use 'variables';

.m-header {
    span.m-dropdown__toggle {
        cursor: pointer;
    }

    .m-brand {
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color') !important;
        }
    }

    .m-header-head {
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color') !important;
        }
    }

    .pulse-badge {
        color: white;
        background: #bf9000;
        position: absolute;
        top: -5px;
        right: -7px;
    }

    .m-header__top {
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color') !important;
        }
    }

    .m-header__bottom {
        @include color-themes.themed('header') {
            background-color: color-themes.get-theme-color('bottom-header-bg');
        }
    }

    .m-brand__logo-wrapper {
        margin-left: 30px;
    }

    .m-brand__logo-wrapper img {
        max-height: 70px;
    }

    .m-nav__item-container {
        height: 100%;
        display: table;

        .dropdown {
            display: table-cell;
            vertical-align: middle;

            .button {
                position: relative;
                margin: 0 auto;
                padding: 0;
                display: table;
                table-layout: fixed;
                outline: none !important;
                vertical-align: middle;
                text-decoration: none;
                text-decoration-color: transparent;

                .m-topbar__welcome {
                    display: table-cell;
                    vertical-align: middle;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #b1b5c1;
                }

                .m-topbar__username {
                    display: table-cell;
                    vertical-align: middle;
                    text-transform: inherit;
                    font-size: 1rem;
                    font-weight: 600;
                    text-align: left;
                    color: #bf9000;
                }

                .m-topbar__userpic {
                    display: table-cell;
                    vertical-align: middle;

                    img {
                        max-width: 41px !important;
                    }
                }
            }

            .dropdown-menu.show {
                top: 10px !important;
                left: -70px !important;
            }

            .dropdown-content {
                @include color-themes.themed() {
                    background-color: color-themes.get-theme-color('bg-color-lighter') !important;
                }

                // padding: 20px 10px;
                @include color-themes.themed() {
                    color: color-themes.get-theme-color('text');
                }

                .icon-sun,
                .icon-moon {
                    padding: 5px 15px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    &:hover {
                        color: variables.$app-accent;
                    }
                }

                .selected {
                    @include color-themes.themed() {
                        background-color: color-themes.get-theme-color('bg-color');
                    }
                }

                .p-dropdown-arrow {
                    position: absolute;
                    z-index: 100;
                    top: -10px;
                    left: 73px;
                    display: block;
                    height: 0;
                    width: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 11px solid #7e94ac;
                    border-bottom-color: #7e94ac !important;
                }

                .p-dropdown-header {
                    border-radius: 6px 6px 0 0;
                    padding: 20px;

                    @include color-themes.themed('header') {
                        background-color: color-themes.get-theme-color('dropdown-header-bg'
                            );
                        border: 2px solid color-themes.get-theme-color('dropdown-border-color');
                    }
                }

                .p-dropdown-body {
                    padding: 20px;

                    @include color-themes.themed('header') {
                        border: 2px solid color-themes.get-theme-color('dropdown-border-color');
                        background-color: color-themes.get-theme-color('bg-color-lighter'
                            );
                    }
                }
            }
        }
    }

    .m-topbar .m-topbar__nav.m-nav>.m-nav__item>.m-nav__link .m-nav__link-entity {
        display: table-cell;
        vertical-align: middle;
        font-size: 1rem;
        font-weight: 500;
        color: #b1b5c1;
        white-space: nowrap;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    // New menu styles
    .list-group.list-group-root {
        padding: 0;
        overflow: hidden;
    }

    .list-group.list-group-root .list-group {
        margin-bottom: 0;
    }

    .list-group-item {
        border-radius: 0;
        border-width: 0 0 0 0;
        color: #676c7b !important;
        text-decoration: none;
        text-decoration-line: none;
        text-decoration-style: initial;
        text-decoration-color: initial;
        vertical-align: middle;
        outline: none !important;
        cursor: pointer;
        width: 100%;

        @include color-themes.themed('navigation') {
            background-color: color.adjust(color-themes.get-theme-color('bg-color-lighter'),
                    $lightness: 5%) !important;
            color: color-themes.get-theme-color('link-color') !important;
        }
    }

    .presentations {
        background-color: #efefef !important;
    }

    .list-group.list-group-root>.list-group-item:first-child {
        border-top-width: 0;
    }

    .list-group.list-group-root>.list-group>.list-group-item {
        padding-left: 30px;
    }

    .list-group.list-group-root>.list-group>.list-group>.list-group-item {
        padding-left: 45px;
    }

    .list-group-item .fa {
        width: 20px;
    }

    .list-group a:hover {
        color: #bf9000 !important;
    }

    .menu_icon {
        font-size: 1.4rem;
        width: 33px;
        text-align: left;
        padding: 0;
        line-height: 0;
    }

    .download-item {
        cursor: pointer;
    }

    .m-menu__main {
        .m-menu__subnav {
            @include color-themes.themed() {
                background-color: color.adjust(color-themes.get-theme-color('bg-color-lighter'),
                        $lightness: 5%) !important;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .m-nav__link-entity {
            display: none !important;
        }
    }

    @media (min-width: 993px) {
        .m-menu__main {
            .m-menu__subnav {
                max-height: 500px;
            }
        }
    }

    .alert-bell-icon {
        color: orangered;
        font-size: 20px;
        position: absolute;
        margin-top: 21px;
        margin-left: -19px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;
    }

    .update-unread {
        font-size: 20px !important;
        color: green !important;
        font-weight: bold !important;
    }

    #load_status {
        cursor: pointer;
        margin-left: 10px;
        display: inline-grid;
        align-content: center;
        justify-content: space-evenly;
        padding-top: 5px;

        #status_kpi {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
        }

        .load_status_text {
            font-size: 1rem;
            font-weight: 500;
            color: #b1b5c1;
            margin-right: 5px;
            margin-left: 5px;
            width: 40px;
        }
    }
}

.m-header--minimize-on {
    .m-brand__logo-wrapper img {
        max-height: 50px !important;
    }
}

.update-popup {
    background-color: white;
    border: hsl(211, 22%, 58%) 3px solid;
    border-radius: 2%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1100px;
    height: 650px;
    padding: 10px 30px;
    z-index: 100000;
    -webkit-box-shadow:
        10px 10px 10px -10px #999,
        10px 10px 10px -10px #999,
        -10px 10px 10px -10px #999;
    -moz-box-shadow:
        10px 10px 10px -10px #999,
        10px 10px 10px -10px #999,
        -10px 10px 10px -10px #999;
    box-shadow:
        10px 10px 10px -10px #999,
        10px 10px 10px -10px #999,
        -10px 10px 10px -10px #999;

    h2 {
        color: hsl(211, 22%, 58%);
        font-weight: bold;
        font-size: x-large;
    }

    p {
        font-size: large;
    }
}

#theme-switcher {
    width: fit-content;
    padding: 0.6rem;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;

    svg {
        transition: all 0.3s ease-in-out;
    }
}

#icon-sun {
    @include color-themes.themed('theme-switch') {
        fill: color-themes.get-theme-color('icon-sun');
    }
}

#icon-moon {
    @include color-themes.themed('theme-switch') {
        fill: color-themes.get-theme-color('icon-moon');
    }
}

.text-reset {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text');
    }
}

#sidebar-menu .accordion {
    margin-top: 5px;
    margin-bottom: 5px;
}

#sidebar-menu .accordion-body ul li a {
    height: 40px;
    text-align: left;
    display: block;
    text-decoration: none;
    line-height: 40px;
    margin-bottom: 1px;

    @include color-themes.themed() {
        color: color-themes.get-theme-color('text');
    }
}

#sidebar-menu .accordion-body ul li {
    list-style: none;
}

#sidebar-menu .accordion-item {
    border: none;
}

#sidebar-menu .accordion-item h2 {
    margin: 0 !important;
}

#sidebar-menu .accordion-button:not(.collapsed) {
    color: #000;

    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color') !important;
        box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 color-themes.get-theme-color('bg-color');
    }
}

#sidebar-menu .accordion-button {
    height: 40px;

    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color');
        color: color-themes.get-theme-color('text');
    }
}

#sidebar-menu .accordion-body {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('offcanvas') !important;
    }
}

#sidebar-menu button:focus {
    @include color-themes.themed() {
        border-color: color-themes.get-theme-color('bg-color') !important;
        box-shadow:
            inset 0 1px 1px color-themes.get-theme-color('bg-color'),
            0 0 8px color-themes.get-theme-color('bg-color') !important;
    }
}

.home-btn {
    display: block;
    text-align: left;
    height: 40px;
    text-decoration: none;
    line-height: 40px;
    padding: 2px 15px;
    font-size: 1rem;

    @include color-themes.themed() {
        color: color-themes.get-theme-color('text');
    }
}

#offcanvasMenu {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color') !important;
    }
}

#mainMenu .accordion-item {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color') !important;
    }
}

.icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .news-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        margin-top: 3px;

        mat-icon {
            padding-top: 4px;
        }
    }

    .icontitle-container {
        margin: 3.5px 0 0 3.5px;
        color: #7e94ac !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
        display: block;
        overflow: hidden;
        text-align: start;

        .m-subheader__title {
            font-size: 12px;
            font-weight: bold;
            margin: auto;

            @include color-themes.themed() {
                color: color-themes.get-theme-color('text');
            }
        }
    }
}

.news-list li:not(:first-child) {
    margin-top: 3px;
}

.news-clickable {
    cursor: pointer;
    border: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.modal-dialog {
    max-width: 700px;

    @media (max-width: 576px) {
        max-width: 500px;
    }
}

/* .modal-header {
    display: block;

    h1 {
        margin: 0;
    }
} */

.modal-body {
    overflow-y: auto;
    height: fit-content;
    /* max-height: 450px; */
}

.title-container-popup {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .news-icon-popup {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        margin-top: 3px;

        .icon-popup {
            padding-top: 4px;
            padding-left: 2.5px;
        }
    }

    .icontitle-container-popup {
        margin: 3.5px 0 0 3.5px;
        color: #7e94ac !important;

        .m-subheader__title-popup {
            font-size: 12px;
            font-weight: bold;
            margin: auto;

            @include color-themes.themed() {
                color: color-themes.get-theme-color('text');
            }
        }
    }
}

.news-modal-title {
    margin-top: 5px;
}

.mobile-app {
    text-align: left;
    /* Ensure the parent container aligns text to the left */
    width: 100%;
    /* Ensure it takes the full width */
    padding-left: 16px;
    /* Add padding to move the text to the right */
    font-size: 13px;
    /* Adjust the font size to make the text smaller */
    color: #575962;
    margin-top: 5px;
}

.pulse-logo {
    display: inline;
}

.pulse-logo-hover {
    display: none;
}

a:hover .pulse-logo {
    display: none;
}

a:hover .pulse-logo-hover {
    display: inline;
}
